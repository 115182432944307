$theme-color: #0d233e;
$theme-color-2: #287dfa;
$theme-color-3: #2273e5;
$theme-color-4: #5d646d;
$theme-color-5: #40CC6F;
$theme-font:  'Roboto', sans-serif;
$white: #fff;
$white-grey: #F5F7FC;
$dark: #000;

$font-size-100: 100px;
$font-size-95: 95px;
$font-size-90: 90px;
$font-size-85: 85px;
$font-size-80: 80px;
$font-size-75: 75px;
$font-size-60: 60px;
$font-size-65: 65px;
$font-size-55: 55px;
$font-size-50: 50px;
$font-size-45: 45px;
$font-size-40: 40px;
$font-size-35: 35px;
$font-size-34: 34px;
$font-size-33: 33px;
$font-size-32: 32px;
$font-size-31: 31px;
$font-size-30: 30px;
$font-size-29: 29px;
$font-size-28: 28px;
$font-size-27: 27px;
$font-size-26: 26px;
$font-size-25: 25px;
$font-size-24: 24px;
$font-size-23: 23px;
$font-size-22: 22px;
$font-size-21: 21px;
$font-size-20: 20px;
$font-size-19: 19px;
$font-size-18: 18px;
$font-size-17: 17px;
$font-size-16: 16px;
$font-size-15: 15px;
$font-size-14: 14px;
$font-size-13: 13px;
$font-size-12: 12px;
$font-size-11: 11px;

$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

$color: #e4e4e4;
$color-1: #eee;
$color-2: #ddd;
$color-3: #8C43FF;
$color-4: #28d5a7;
$color-5: #f9b851;
$color-6: #CC08E9;
$color-7: #FF8C2A;
$color-8: #bfff02;
$color-9: #307CF3;
$alter-color: #3b5998;
$alter-color-2: #1da1f2;
$alter-color-3: #e1306c;
$alter-color-4: #2877B5;
$alter-color-5: #f00125;
$alter-color-6: #fb6340;

$laptop_l: '(max-width: 1440px)';
$laptop_m: '(max-width: 1366px)';
$laptop_m_two: '(max-width: 1280px)';
$laptop_m_three: '(max-width: 1200px)';
$laptop_m_four: '(max-width: 1199px)';
$laptop_m_five: '(max-width: 1024px)';
$laptop_ls: 'only screen and (max-width: 1279px) and (min-width: 320px)';
$medium_device: 'only screen and (min-width: 992px) and (max-width: 1200px)';
$ms_device: 'only screen and (max-width: 1200px) and (min-width: 320px)';
$tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
$tab_device_two: '(max-width: 768px)';
$large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
$large_mobile_2: 'only screen and (min-width: 481px) and (max-width: 767px)';
$large_mobile_two: ' (max-width: 600px)';
$large_mobile_three: ' (max-width: 480px)';
$small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';
$small_mobile_two: '(max-width: 320px)';
$small_mobile_three: '(max-width: 400px)';
$small_mobile_four: '(max-width: 425px)';
$small_mobile_five: '(max-width: 375px)';

$extra-device: '(max-width: 575px)';
