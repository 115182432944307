/*
	Template Name: Trizen
	Author: TechyDevs
	Email: contact@techydevs.com
	Website: techydevs.com
	Description: Trizen - Travel Booking HTML5 Template
	Version: 1.0
*/

/*------------------------------------------------------------------
[Table of contents]

	+ General
		- general Styles
		- common-style
		- Preloader
		- theme-btn
		- button-shared
		- padding
		- margin
		- preloader
		- section-heading
		- custom-checkbox
		- Back To Top
		- and more
	+ Header
		- Logo
		- login
		- sign up
		- Main Navigation
		- Dropdown
		- mobile menu
	+ Content
		+ Main Files
			- index.html
			- index2.html
			- index3.html
			- index4.html
			- index5.html
			- index6.html
	+ Banner Area
			- Breadcrumb
	+ Tour
	        - Tour Full Width
	        - Tour Grid
	        - Tour List
	        - Tour Left Sidebar
	        - Tour Right Sidebar
	        - Tour Details
	        - Tour Booking
	+ Cruise
	        - Cruises
	        - Cruise List
	        - Cruise Sidebar
	        - Cruise Details
	        - Cruise Booking
	+ Pages
			- Dashboard
			- User Profile
			- Become Local Expert
			- Destinations
			- About
			- Our Services
			- Payment
			- Faq
			- Contact
			- 404 Page
			- Recover Password
			- Coming Soon
	+ blog
			- Blog Full Width
			- Blog Grid
			- Blog Sidebar
			- Blog Detail
	+ Flight
	        - Flight Grid
	        - Flight List
	        - Flight Sidebar
	        - Flight Details
	        - Flight Booking
	 + Hotel
	        - Hotel Grid
	        - Hotel List
	        - Hotel Sidebar
	        - Hotel Details
	        - Hotel Booking
	 + Car
	        - Car Grid
	        - Car List
	        - Car Sidebar
	        - Car Details
	        - Car Booking
	+ Footer
		+ Top Footer
			- Company Details
			- Other Licks
			- Subscriber form
		+ Bottom Footer
		    - Copyright
		    - Terms & Conditions
		    - Privacy Policy
		    - Help Center
		    - Social Profile
	+ Responsive Design Styles
		    - $laptop_l: '(max-width: 1440px)';
            - $laptop_m: '(max-width: 1366px)';
            - $laptop_m_two: '(max-width: 1280px)';
            - $laptop_m_three: '(max-width: 1200px)';
            - $laptop_m_four: '(max-width: 1199px)';
            - $laptop_m_five: '(max-width: 1024px)';
            - $laptop_ls: 'only screen and (max-width: 1279px) and (min-width: 320px)';
            - $medium_device: 'only screen and (min-width: 992px) and (max-width: 1200px)';
            - $ms_device: 'only screen and (max-width: 1200px) and (min-width: 320px)';
            - $tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
            - $tab_device_two: '(max-width: 768px)';
            - $large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
            - $large_mobile_two: ' (max-width: 600px)';
            - $large_mobile_three: ' (max-width: 480px)';
            - $small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';
            - $small_mobile_four: '(max-width: 425px)';
            - $small_mobile_three: '(max-width: 400px)';
            - $small_mobile_five: '(max-width: 375px)';
            - $small_mobile_two: '(max-width: 320px)';
-------------------------------------------------------------------*/
// variable scss
@import "partials/variables";

// mixin scss
@import "partials/mixins";
@import "partials/keyframes";
// common scss
@import "partials/common";
// button scss
@import "partials/button";
// general scss
@import "partials/general";
// icons scss
@import "partials/icons";
// contact scss
@import "partials/contact";
// blockquote scss
@import "partials/blockquote";
// breadcrumb scss
@import "partials/breadcrumb";
// menu scss
@import "partials/menu";
// modal scss
@import "partials/modal";
// author scss
@import "partials/author";
// tags scss
@import "partials/tags";
// routes scss
@import "partials/routes";
// particles scss
@import "partials/particles";
// progressbar scss
@import "partials/progressbar";
// cards scss
@import "partials/cards";
// pricing scss
@import "partials/pricing";
// faq scss
@import "partials/faq";
// hero scss
@import "partials/hero";
// hotel scss
@import "partials/hotel";
// team-member scss
@import "partials/team-member";
// table scss
@import "partials/table";
// chart scss
@import "partials/chart";
// file-upload scss
@import "partials/file-upload";
// error scss
@import "partials/error";
// blog scss
@import "partials/custom";
// counter scss
@import "partials/counter";
// category scss
@import "partials/cart";
// call-to-action scss
@import "partials/call-to-action";
// dashboard scss
@import "partials/dashboard";
// clientlogo scss
@import "partials/clientlogo";
// comment scss
@import "partials/comment";
// icon-box scss
@import "partials/icon-box";
// tab scss
@import "partials/tab";
// testimonial scss
@import "partials/testimonial";
// sidebar scss
@import "partials/sidebar";
// map
@import "partials/map";

// footer scss
@import "partials/footer";
