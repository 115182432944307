
/*========= package-item =========*/
.package-card {
  background-color: $white;
  @include border-radius(8px);
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.05));
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 1px solid rgba(127, 137, 150, 0.1);
  .package-tooltip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @include border-radius(4px 4px 0 0);
    .package__tooltip {
      background-color: $theme-color-2;
      color: $white;
      font-weight: $font-weight-semi-bold;
      text-align: center;
      display: block;
      @include border-radius(4px 4px 0 0);
      padding: 15px 0;
      font-size: $font-size-35;
      position: relative;
      z-index: 1;
    }
  }
  .package-title {
    margin-bottom: 30px;
    padding: 30px 0 35px 0;
    position: relative;
    background-color: $white-grey;
    .package__title {
      font-size: $font-size-25;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      margin-bottom: 20px;
      color: $theme-color;
    }
    .package__price {
      font-size: $font-size-50;
      font-weight: $font-weight-bold;
      color: $theme-color-2;
    }
    .package__month {
      color: $theme-color-2;
      font-size: $font-size-15;
      padding-top: 3px;
    }
    .hero-svg {
     height: 40px;
      width: 101%;
      left: -1px;
      bottom: -1px;
    }
  }
  .list-items {
    li {
      &.text-disabled {
        color: rgba(128, 137, 150, 0.5);
        text-decoration: line-through;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .btn-box {
    padding: 30px;
    .theme-btn {
      display: block;
      text-align: center;
    }
  }
}

/*========= package-card-active =========*/
.package-card-active {
  @include transform(scale(1.1));
  z-index: 3;
  @media #{$tab_device} {
    @include transform(scale(1));
  }
  @media #{$large_mobile} {
    @include transform(scale(1));
  }
  @media #{$small_mobile} {
    @include transform(scale(1));
  }
  .package-title {
    background-color: $theme-color-2;
    .package__price,
    .package__month,
    .package__title {
      color: $white;
    }
  }
}