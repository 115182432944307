/*====================================================
   error-area
 ====================================================*/
.error-img {
  svg {
    width: 100%;
    @media #{$large_mobile_three} {
      height: 200px;
    }
  }
  image {
    width: 100%;
    @media #{$large_mobile_three} {
      height: 100%;
    }
  }
}
