.routes {
  background-color: $white;
  @include box-shadow(0 2px 7px 0 rgba(0,0,0,.15));
}
.route-item {
  padding: 13px 14px;
  border-bottom: 1px solid rgba(128, 137, 150, 0.1);
  @include transition(0.2s);
  background-color: $white;
  position: relative;
  &:hover {
    @include box-shadow(0px 0px 8px 2px rgba(0,0,0,0.15));
    z-index: 1;
  }
}

.popular-connection-links {
  .list-group {
    .list-group-item {
      margin-right: 0;
      margin-left: 0;
    }
    .list-group-link {
      border-top: 0;
      border-bottom: 1px solid rgba(128, 137, 150, 0.1);
      padding-left: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
/*===== bus-stop-overview ======*/
.bus-stop-overview {
  @media #{$tab_device} {
    padding-left: 0!important;
    padding-top: 30px;
  }
  @media #{$large_mobile} {
    padding-left: 0!important;
    padding-top: 30px;
  }
  @media #{$small_mobile} {
    padding-left: 0!important;
    padding-top: 30px;
  }
  .list-group-item {
    padding-left: 50px;
    &.active {
      background-color: $white;
      @include box-shadow(0px 0px 8px 2px rgba(0,0,0,0.15));
      z-index: 1;
      color: $theme-color-4;
      .bus-stop-overview-content {
        display: block;
      }
      .list-group-link {
        font-weight: $font-weight-bold;
        border-top: 0;
        color: $theme-color;
        padding-bottom: 4px;
      }
      .icon-arrow {
        display: none;
      }
      .icon-marker {
        color: $theme-color-2;
      }
    }
  }
}
.bus-stop-overview-content {
  display: none;
}
/*===== bus-stop-overview-content__address ======*/
.bus-stop-overview-content__address {
  span {
    display: block;
    line-height: 24px;
    font-size: $font-size-15;
  }
}
.bus-stop-overview__link {
  span {
    display: block;
    line-height: 18px;
  }
  a {
    color: $theme-color-2;
    &:hover {
      text-decoration: underline;
    }
  }
}