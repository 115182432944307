/*====================================================
    team-card
 ====================================================*/
.team-card {
  @include border-radius(12px);
  padding-top: 20px;
  margin-bottom: 60px;
  .card-img {
    width: 190px;
    height: 190px;
    @include border-radius(100%);
    border: 8px solid $white;
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    margin-left: auto;
    margin-right: auto;
    margin-top: -60px;
    img {
      height: 100%;
      @include border-radius(100%);
    }
  }
  .card-body {
    padding: 20px;
  }
}